import {MovieClip} from "../../components/MovieClip";

export const Service = ({title, price, videoId}) => {
    return (
        <div>
            <div>
                <div>
                    {title}
                </div>
                <div>
                    {price}
                </div>
            </div>
            <div>
            <   MovieClip videoId={videoId} />
            </div>
        </div>
    )
}