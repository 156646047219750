import React from "react";
import YouTube from "react-youtube";
import {useWindowSize} from "../hooks/useWindowSize"

export function MovieClip({videoId}) {
     const screenSize = useWindowSize();
     const width =  Math.min(640, screenSize.width);

     const ratio = 1.641;
     const height = Math.round(width/ratio);
     const options = {
       height: height,
       width: width,
       playerVars: {
         autoplay: 1,
         controls: 1,
       },
     };
 
     return <YouTube videoId={videoId} opts={options}
                      onReady={event => event.target.pauseVideo()} />;

 }