import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import "./services.css"
import { Service } from "./Service";
import BookNowButton from "../../components/BookNowButton"

const Services = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main className="page container">
        <PageHeader title="Services" description="" />
        <BookNowButton/>
        <div className="services">
          <Service title={"Piano Solo"} price={"$200-300/hour"} videoId={"9EjEmZelv1E"}/>
          <Service title={"Piano/Cello Duo"} price={"$400-600/hour"} videoId={"twg_rS-840A"}/>
          <Service title={"Trio (Violin, Cello and Piano)"} price={"$600-800/hour"} videoId={"c0sIy7OypQM"}/>
          <Service title={"Duo (Voice and Piano) / Trio (+Cajon)"} price={"$400-800/hour"} videoId={"xqPm7mV_Wx8"}/>
        </div>
        <BookNowButton/>
      </main>
      <Footer />
    </>
  );
};

export default Services;
