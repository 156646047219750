import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import {MovieClip} from "../../components/MovieClip";
import "./videos.css"
import BookNowButton from "../../components/BookNowButton"

const Videos = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main className="page container">
        <PageHeader title="Videos" description="" />
        <BookNowButton/>
        <div className="videos">
            <MovieClip videoId="9EjEmZelv1E" />
            <MovieClip videoId="c0sIy7OypQM" />
            <MovieClip videoId="d5TcQ4lybAc" />
            

            <MovieClip videoId="D5aCF95c8wY" />
            <MovieClip videoId="9KA1QH5w-jQ" />
            <MovieClip videoId="twg_rS-840A" />




            
        </div>
      </main>
      <BookNowButton/>
   
      <Footer />
    </>
  );
};

export default Videos;
