import PageHeader from "../../components/PageHeader";
import Form from "../../components/Form";
import ContactInfo from "../../components/ContactInfo";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
const Contact = ({ name, email, location, phone }) => {

  const location2 = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location2]);

  return (
    <>
      {/* Main Contact Page */}
      <main className="contact container">
        {/* Display the page header */}
        <PageHeader title="Contact" description="Get in touch" />

        <div className="contactWrap container">
          <div className="row">
            {/* Display the contact form */}
            <div className="col-12 col-lg-6">
              <Form />
            </div>

            {/* Display the contact information */}
            <div className="col-12 col-lg-6">
              <ContactInfo name={name} location={location} email={email} phone={phone} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Contact;
