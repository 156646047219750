import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import "./photos.css"
import photo1 from "../../images/photos/1.jpg"
import photo2 from "../../images/photos/2.jpg"
import photo3 from "../../images/photos/3.jpg"
import photo4 from "../../images/photos/4.jpg"
import photo5 from "../../images/photos/5.jpg"
import photo6 from "../../images/photos/6.jpg"
import photo7 from "../../images/photos/7.jpg"
import photo8 from "../../images/photos/8.jpg"
import photo9 from "../../images/photos/9.jpg"
import photo10 from "../../images/photos/10.jpg"
import photo11 from "../../images/photos/11.jpg"
import photo12 from "../../images/photos/12.jpg"
import photo13 from "../../images/photos/13.jpg"
import photo14 from "../../images/photos/14.jpg"
import photo15 from "../../images/photos/15.jpg"
import photo16 from "../../images/photos/16.jpg"
import photo17 from "../../images/photos/17.jpg"
import photo18 from "../../images/photos/18.jpg"
import photo19 from "../../images/photos/19.jpg"
import photo20 from "../../images/photos/20.jpg"
import BookNowButton from "../../components/BookNowButton"


const Photos = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <main className="page container">
        <PageHeader title="Photos" description="" />
        <BookNowButton/>
        <div className="photos">
          <img src={photo1} alt="photo-1"/>
          <img src={photo2} alt="photo-2"/>
          <img src={photo3} alt="photo-3"/>
          <img src={photo4} alt="photo-4"/>
          <img src={photo5} alt="photo-5"/>
          <img src={photo6} alt="photo-6"/>
          <img src={photo7} alt="photo-7"/>
          <img src={photo8} alt="photo-8"/>
          <img src={photo9} alt="photo-9"/>
          <img src={photo10} alt="photo-10"/>
          <img src={photo11} alt="photo-11"/>
          <img src={photo12} alt="photo-12"/>
          <img src={photo13} alt="photo-13"/>
          <img src={photo14} alt="photo-14"/>
          <img src={photo15} alt="photo-15"/>
          <img src={photo16} alt="photo-16"/>
          <img src={photo17} alt="photo-17"/>
          <img src={photo18} alt="photo-18"/>
          <img src={photo19} alt="photo-19"/>
          <img src={photo20} alt="photo-20"/>
        </div>

      </main>
      <BookNowButton/>
      <Footer />
    </>
  );
};

export default Photos;
