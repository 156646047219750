import { Link } from "react-router-dom";
import Button from "../components/Button";


const BookNowButton = () => {
  return (
    <div className="bookNowButtonContainer">
      <Link to="/contact">
        <Button name="Book Now" />
      </Link>
    </div>

  );
};

export default BookNowButton;
